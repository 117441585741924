<ng-container
    *ngIf="{
        shouldShowError: shouldShowError$ | async,
        listErrors: listErrors$ | async
    } as $"
>
    <ul *ngIf="$.shouldShowError" class="typo-sm" @error>
        <li
            *ngFor="let error of $.listErrors"
            class="flex items-center space-x-4"
        >
            <interacta-icon class="text-text-error h-16 w-16" icon="warning" />
            <label
                role="alert"
                [for]="inputId"
                [ngClass]="{
                    'text-text-error': textColor === 'error',
                    'text-text': textColor === 'regular'
                }"
                >{{ error.message | translate: error.params }}
            </label>
        </li>
    </ul>
</ng-container>
