import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { InputTextV2Component } from '../input-text-V2/input-text-V2.component';

@Component({
    selector: 'interacta-input-password-v2',
    templateUrl: './input-password-v2.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [InputTextV2Component, TranslateModule],
})
export class InputPasswordV2Component {
    @Input() control!: UntypedFormControl;
    @Input() autocomplete = true;

    showPassword = false;
}
