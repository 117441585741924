import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'array',
    standalone: true,
})
export class ArrayPipe implements PipeTransform {
    transform(size: number): number[] {
        return new Array(size).fill(0).map((_, idx) => idx);
    }
}
