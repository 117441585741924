import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnChanges,
    OnDestroy,
    SimpleChanges,
    inject,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { IconComponent } from '@interacta-shared/ui-icons';
import { TranslateModule } from '@ngx-translate/core';
import { BehaviorSubject, Subject, concat, merge, of } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { openHeightAnimation } from '../../animations';
import { ValidationMessage } from '../../model/validation-message.model';
import { ShowErrorsService } from '../../services/show-errors.service';

@Component({
    standalone: true,
    imports: [CommonModule, IconComponent, TranslateModule],
    selector: 'interacta-show-errors',
    templateUrl: './show-errors.component.html',
    styles: [],
    providers: [ShowErrorsService],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [openHeightAnimation('error', '*')],
})
export class ShowErrorsComponent implements OnChanges, OnDestroy {
    @Input({ required: true }) control!: AbstractControl;
    @Input() inputId?: string;
    @Input() textColor: 'error' | 'regular' = 'error';

    listErrors$ = new BehaviorSubject<ValidationMessage[]>([]);
    shouldShowError$ = new BehaviorSubject(true);

    private showErrorService = inject(ShowErrorsService);
    private destroyOrChange$ = new Subject<void>();

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['control']) {
            this.destroyOrChange$.next();
            const status$ = concat(
                of(this.control.status),
                this.control.statusChanges,
            );

            merge(this.control.valueChanges, status$)
                .pipe(
                    takeUntil(this.destroyOrChange$),
                    map((_) =>
                        this.showErrorService.computeListOfErrors(this.control),
                    ),
                )
                .subscribe((errors) => this.listErrors$.next(errors));

            status$
                .pipe(
                    takeUntil(this.destroyOrChange$),
                    map((_) => this.shouldShowErrors()),
                )
                .subscribe((shouldShowError) =>
                    this.shouldShowError$.next(shouldShowError),
                );
        }
    }

    ngOnDestroy(): void {
        this.destroyOrChange$.next();
    }

    private shouldShowErrors(): boolean {
        return !!(
            this.control &&
            this.control.errors &&
            (this.control.dirty || this.control.touched)
        );
    }
}
