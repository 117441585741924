import {
    animate,
    AnimationEvent,
    style,
    transition,
    trigger,
} from '@angular/animations';
import {
    CdkConnectedOverlay,
    CdkOverlayOrigin,
    ConnectedPosition,
} from '@angular/cdk/overlay';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { MenuPositionX, MenuPositionY } from '../../model';

/**
 * A generic menu with an enter and leave animation
 */
@Component({
    selector: 'interacta-menu',
    templateUrl: './menu.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('menu', [
            transition(':enter', [
                style({ opacity: 0, transform: 'scale(0.8)' }),
                animate(
                    '100ms ease',
                    style({ opacity: 1, transform: 'scale(1)' }),
                ),
            ]),
            transition(':leave', [
                animate(
                    '100ms ease',
                    style({ opacity: 0, transform: 'scale(0.8)' }),
                ),
            ]),
        ]),
    ],
    standalone: true,
    imports: [CdkConnectedOverlay],
})
export class MenuComponent implements OnChanges, OnInit, OnDestroy {
    @Input({ required: true }) origin!: CdkOverlayOrigin;
    @Input() open = false;
    @Input() positionX: MenuPositionX = 'before';
    @Input() positionY: MenuPositionY = 'below';
    @Input() disableAnimation = false;
    @Input() overridePositions?: ConnectedPosition[];

    @Output() closing = new EventEmitter<void>();
    @Output() closed = new EventEmitter();

    @ViewChild(CdkConnectedOverlay) overlay?: CdkConnectedOverlay;

    positions: ConnectedPosition[] = [];

    @HostListener('keydown.escape')
    onKeydownEscapeHandler(_event: KeyboardEvent): void {
        if (this.open) {
            this.closing.emit();
        }
    }

    destroy$ = new Subject<void>();

    constructor(private router: Router) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['overridePositions'] && this.overridePositions) {
            this.positions = this.overridePositions;
        }

        if (changes['positionX'] || changes['positionY']) {
            this.positions = [
                this.cdkPosition(this.positionX, this.positionY),
                this.cdkPosition(
                    this.positionX,
                    this.invertPositionY(this.positionY),
                ),
                this.cdkPosition(
                    this.invertPositionX(this.positionX),
                    this.positionY,
                ),
                this.cdkPosition(
                    this.invertPositionX(this.positionX),
                    this.invertPositionY(this.positionY),
                ),
            ];
        }
    }

    ngOnInit(): void {
        this.router.events
            .pipe(
                filter(
                    (event) => event instanceof NavigationStart && this.open,
                ),
                takeUntil(this.destroy$),
            )
            .subscribe(() => {
                this.closed.emit();
                this.closing.emit();
                // Detach to retain overlay in case of navigation back, dispose when destroy
                this.overlay?.overlayRef.detach();
            });
    }

    ngOnDestroy(): void {
        this.overlay?.overlayRef?.dispose();
        this.destroy$.next();
    }

    onAnimationEnd(event: AnimationEvent): void {
        if (event.toState === 'void') {
            this.closed.emit();
        }
    }

    private cdkPosition(
        posX: MenuPositionX,
        posY: MenuPositionY,
    ): ConnectedPosition {
        if (posX === 'before' && posY === 'above') {
            return {
                originX: 'end',
                originY: 'top',
                overlayX: 'end',
                overlayY: 'bottom',
            };
        } else if (posX === 'before' && posY === 'below') {
            return {
                originX: 'end',
                originY: 'bottom',
                overlayX: 'end',
                overlayY: 'top',
            };
        } else if (posX === 'after' && posY === 'above') {
            return {
                originX: 'start',
                originY: 'top',
                overlayX: 'start',
                overlayY: 'bottom',
            };
        } else {
            // (posX === 'after' && posY === 'below')
            return {
                originX: 'start',
                originY: 'bottom',
                overlayX: 'start',
                overlayY: 'top',
            };
        }
    }

    private invertPositionX(position: MenuPositionX): MenuPositionX {
        return position === 'after' ? 'before' : 'after';
    }

    private invertPositionY(position: MenuPositionY): MenuPositionY {
        return position === 'above' ? 'below' : 'above';
    }
}
