import {
    AfterViewInit,
    Directive,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
} from '@angular/core';
import { Subject } from 'rxjs';
import {
    debounceTime,
    distinctUntilChanged,
    map,
    takeUntil,
} from 'rxjs/operators';
import { ResizeElementService } from '../services';

export interface ResizeChange {
    width: number;
    height: number;
}

export type ResizeAxis = 'width' | 'height' | 'both';

@Directive({
    selector: '[interactaResize]',
    standalone: true,
})
export class ResizeDirective implements AfterViewInit, OnDestroy {
    @Input() resizeAxis: ResizeAxis = 'both';

    @Output() changeSize = new EventEmitter<ResizeChange>();

    private destroy$ = new Subject<void>();

    constructor(
        private elementRef: ElementRef,
        private resizeElementService: ResizeElementService,
    ) {}

    ngAfterViewInit(): void {
        this.resizeElementService
            .observeElement(this.elementRef.nativeElement)
            .pipe(
                debounceTime(50),
                map((resize) => ({
                    width: resize.contentRect.width,
                    height: resize.contentRect.height,
                })),
                distinctUntilChanged((x, y) => {
                    switch (this.resizeAxis) {
                        case 'width':
                            return x.width === y.width;
                        case 'height':
                            return x.height === y.height;
                        case 'both':
                            return x.width === y.width && x.height === y.height;
                    }
                }),
                takeUntil(this.destroy$),
            )
            .subscribe((size) => {
                this.changeSize.emit(size);
            });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }
}
