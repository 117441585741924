import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Observable, concat, of } from 'rxjs';

@Pipe({
    name: 'formControlValueV2$',
    standalone: true,
})
export class FormControlValueV2Pipe implements PipeTransform {
    transform(control: AbstractControl | null | undefined): Observable<any> {
        return control == null
            ? of(null)
            : concat(of(control.value), control.valueChanges);
    }
}
