import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Size } from '@interacta-shared/ui';
import { IconComponent } from '@interacta-shared/ui-icons';
import { NgClass } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    standalone: true,
    selector: 'interacta-warning-badge',
    templateUrl: './warning-badge.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [IconComponent, NgClass, TranslateModule],
})
export class WarningBadgeComponent {
    @Input({ required: true }) label!: string;
    @Input() severity: 'warning' | 'error' | 'info' = 'warning';
    @Input() showAsHtml = false;
    @Input() showIcon = true;
    @Input() iconSize: Extract<Size, 'regular' | 'small'> = 'regular';
}
