import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Optional,
    Output,
    SimpleChanges,
} from '@angular/core';
import { IconComponent } from '@interacta-shared/ui-icons';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CheckboxState, Size } from '../../model';
import { CheckboxGroupIndeterminateService } from '../checkbox-group-indeterminate/checkbox-group-indeterminate.component';
import { CheckboxGroupService } from '../checkbox-group/checkbox-group.component';

@Component({
    selector: 'interacta-checkbox',
    templateUrl: './checkbox.component.html',
    styles: [
        `
            :host {
                display: block;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, NgClass, IconComponent, AsyncPipe],
})
export class CheckboxComponent<T> implements OnInit, OnChanges, OnDestroy {
    @Input() checked?: CheckboxState;
    @Input() disabled = false;
    @Input() variant: 'square' | 'circle' = 'square';
    @Input() size: Extract<Size, 'regular' | 'small' | 'extra-small'> =
        'regular';
    @Input() value?: T;
    @Input() fullWidth?: boolean;
    @Input() labelPosition: 'after' | 'before' = 'after';
    @Input() align: 'start' | 'center' = 'center';
    @Input() wordBreak: 'break-all' | 'break-word' | 'normal' = 'break-all';

    @Output() changeCheck = new EventEmitter<CheckboxState>();

    checked$ = new BehaviorSubject<CheckboxState>(false);

    private destroy$ = new Subject<void>();

    constructor(
        @Optional()
        private checkboxGroupIndeterminateService?: CheckboxGroupIndeterminateService<T>,
        @Optional() private checkboxGroupService?: CheckboxGroupService<T>,
    ) {}

    ngOnInit(): void {
        if (this.checkboxGroupIndeterminateService) {
            this.checkboxGroupIndeterminateService.selectedValues
                .pipe(takeUntil(this.destroy$))
                .subscribe((selectedValues) => {
                    if (this.value) {
                        const state = selectedValues.get(this.value) ?? false;
                        this.checked$.next(state);
                    }
                });
        }
        if (this.checkboxGroupService) {
            this.checkboxGroupService.selectedValues
                .pipe(takeUntil(this.destroy$))
                .subscribe(({ values }) => {
                    if (this.value) {
                        this.checked$.next(values.has(this.value));
                    }
                });
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['checked']) {
            this.checked$.next(this.checked ?? false);
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }

    toggle(selected: CheckboxState): void {
        let nextState: CheckboxState = !selected;

        if (this.checkboxGroupIndeterminateService && this.value) {
            nextState = this.checkboxGroupIndeterminateService.nextState({
                item: this.value,
                state: selected,
            });
        }

        if (this.checkboxGroupService && this.value) {
            nextState = this.checkboxGroupService.nextState(this.value);
        }
        this.changeCheck.emit(nextState);
    }
}
