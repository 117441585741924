import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Injectable,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CheckboxState } from '../../model';

type CheckboxItem<T> = {
    item: T;
    state: CheckboxState;
};

@Injectable()
export class CheckboxGroupIndeterminateService<T> {
    selectedValues = new BehaviorSubject<Map<T, CheckboxState>>(new Map());
    canBeIndeterminate = new Map<T, boolean>();

    setCanBeIndeterminate(record: Record<number, boolean>): void {
        this.canBeIndeterminate.clear();
        Object.entries(record).forEach(([key, value]) =>
            this.canBeIndeterminate.set(parseInt(key) as any, value),
        );
    }

    changeValues(values: CheckboxItem<T>[]): void {
        this.selectedValues.next(
            new Map(values?.map((v) => [v.item, v.state])),
        );
    }

    nextState(value: CheckboxItem<T>): CheckboxState {
        let nextState: CheckboxState;
        if (value.state === true) {
            this.selectedValues.value.delete(value.item);
            nextState = false;
        } else if (value.state === 'indeterminate') {
            this.selectedValues.value.set(value.item, true);
            nextState = true;
        } else {
            if (this.canBeIndeterminate.get(value.item)) {
                this.selectedValues.value.set(value.item, 'indeterminate');
                nextState = 'indeterminate';
            } else {
                this.selectedValues.value.set(value.item, true);
                nextState = true;
            }
        }
        this.selectedValues.next(this.selectedValues.value);
        return nextState;
    }
}

@Component({
    selector: 'interacta-checkbox-group-indeterminate',
    template: `<ng-content></ng-content>`,
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CheckboxGroupIndeterminateComponent),
            multi: true,
        },
        CheckboxGroupIndeterminateService,
    ],
    standalone: true,
})
export class CheckboxGroupIndeterminateComponent<T>
    implements OnChanges, OnInit, OnDestroy, ControlValueAccessor
{
    @Input() canBeIndeterminate: Record<number, boolean> = {};
    @Output() changeSelection = new EventEmitter<CheckboxItem<T>[]>();

    private onControlChange?: (value: CheckboxItem<T>[]) => void;
    private onControlTouched?: () => void;
    private destroy$ = new Subject<void>();

    constructor(
        private checkboxGroupIndeterminateService: CheckboxGroupIndeterminateService<T>,
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['canBeIndeterminate']) {
            this.checkboxGroupIndeterminateService.setCanBeIndeterminate(
                this.canBeIndeterminate,
            );
        }
    }

    ngOnInit(): void {
        this.checkboxGroupIndeterminateService.selectedValues
            .pipe(takeUntil(this.destroy$))
            .subscribe((selectedValues) => {
                this.changeSelection.emit(
                    [...selectedValues.entries()].map(([item, state]) => ({
                        item,
                        state,
                    })),
                );

                if (this.onControlChange) {
                    this.onControlChange(
                        [...selectedValues.entries()].map(([item, state]) => ({
                            item,
                            state,
                        })),
                    );
                }
            });

        if (this.onControlTouched) {
            this.onControlTouched();
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }

    writeValue(values: CheckboxItem<T>[]): void {
        this.checkboxGroupIndeterminateService.changeValues(values);
    }

    registerOnChange(fn: (value: CheckboxItem<T>[]) => void): void {
        this.onControlChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onControlTouched = fn;
    }
}
