import { Injectable, inject } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ValidationKey } from '@interacta-shared/util';
import { TranslateService } from '@ngx-translate/core';
import { ValidationMessage } from '../model/validation-message.model';

@Injectable()
export class ShowErrorsService {
    private readonly prefix = 'UI.VALIDATION_MESSAGE';
    private translateService = inject(TranslateService);

    private getMessageKey(error: string) {
        return `${this.prefix}.${error}`;
    }

    private isKnownError(errorKey: string) {
        return this.translateService.instant(errorKey) !== errorKey;
    }

    private getMessage(error: string, params: unknown): ValidationMessage {
        const key = this.getMessageKey(error);
        if (!this.isKnownError(key)) {
            if (error === ValidationKey.MESSAGE && typeof params === 'string') {
                return { message: params };
            } else {
                return {
                    message: this.getMessageKey(ValidationKey.UNKNOWN),
                    params: {
                        key,
                    },
                };
            }
        }
        return { message: key, params };
    }

    public computeListOfErrors(control: AbstractControl): ValidationMessage[] {
        if (control && control.errors) {
            const firstErrorKey = Object.keys(control.errors)[0];
            return firstErrorKey
                ? [
                      this.getMessage(
                          firstErrorKey,
                          control.errors[firstErrorKey],
                      ),
                  ]
                : [];
        } else return [];
    }
}
