import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

// Servizio creato per instaziare un solo ResizeObserver per tutta l'applicazione - ottimizzazione
@Injectable({
    providedIn: 'root',
})
export class ResizeElementService {
    private resizeObserver: ResizeObserver;

    // mappa degli elementi tracciati
    private elementMap: Map<HTMLElement, Subject<ResizeObserverEntry>> =
        new Map();

    constructor() {
        this.resizeObserver = new ResizeObserver(
            (entries: ResizeObserverEntry[]) => {
                entries.forEach((entry) => {
                    if (!(entry.target instanceof HTMLElement)) return;

                    const subject = this.elementMap.get(entry.target);
                    subject?.next(entry);
                });
            },
        );
    }

    observeElement(element: HTMLElement): Observable<ResizeObserverEntry> {
        return new Observable((sub) => {
            let subject = this.elementMap.get(element);

            if (!subject) {
                subject = new Subject<ResizeObserverEntry>();
                this.elementMap.set(element, subject);
                this.resizeObserver.observe(element);
            }

            subject.subscribe(sub);

            return () => {
                this.resizeObserver.unobserve(element);

                subject?.complete();

                this.elementMap.delete(element);
            };
        });
    }
}
