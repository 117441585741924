import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { concat, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

export interface InputState {
    invalid: boolean;
    disabled: boolean;
}

@Pipe({
    name: 'inputState',
    standalone: true,
})
export class InputStatePipe implements PipeTransform {
    transform(
        control: AbstractControl,
        isReadonly: boolean,
    ): Observable<InputState> {
        return concat(of(control), control.statusChanges).pipe(
            map(() => ({
                invalid: control.invalid && (control.dirty || control.touched),
                disabled: control.disabled || isReadonly,
            })),
        );
    }
}
