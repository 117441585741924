/**
 * Idetifies validation error, see {@link https://angular.io/api/forms/Validators}
 */
export enum ValidationKey {
    REQUIRED = 'required',
    MIN_VALUE = 'min',
    MAX_VALUE = 'max',
    MIN_LENGTH = 'minlength',
    MAX_LENGTH = 'maxlength',
    EMAIL = 'email',

    // Utility keys, not related to specific validators

    /**
     * Used to display a dynamic message, built at runtime
     */
    MESSAGE = 'message',

    /**
     * Used as fallback for every validation error not matching any i18n label
     */
    UNKNOWN = 'unknown',
}
