import { HttpErrorResponse } from '@angular/common/http';
import { ValidationErrors } from '@angular/forms';
import {
    assertUnreachable,
    isDefined,
    ValidationKey,
} from '@interacta-shared/util';
import { CommonValidationKey } from '@interacta-shared/util-common';
import {
    IValidationError,
    IValidationErrorPayload,
    ServerValidationErrorCode,
    serverValidationErrorCodes,
} from './validation-error.model';

export const isValidationErrorPayload = (
    object: unknown,
): object is IValidationErrorPayload => {
    return (
        object != null &&
        typeof object === 'object' &&
        'validationErrors' in object &&
        object.validationErrors != null &&
        typeof object.validationErrors === 'object' &&
        'list' in object.validationErrors
    );
};

export const getValidationErrorPayload = (
    object: unknown,
): IValidationErrorPayload | null => {
    if (
        isDefined(object) &&
        object instanceof HttpErrorResponse &&
        isValidationErrorPayload(object.error)
    ) {
        return object.error;
    } else {
        return null;
    }
};

export const getValidationErrors = (error: unknown): IValidationError[] => {
    const errors = getValidationErrorPayload(error);
    return errors?.validationErrors.list ?? [];
};

export const hasValidationErrorByCode = (
    object: unknown,
    code: string,
): boolean =>
    getValidationErrors(object).some((item) => item.code === code) ?? false;

const REQUIRED_FIELD: ServerValidationErrorCode = 'REQUIRED_FIELD';
const INVALID_VALUE: ServerValidationErrorCode = 'INVALID_VALUE';

export const hasRequiredFieldValidationError = (object: unknown): boolean => {
    return hasValidationErrorByCode(object, REQUIRED_FIELD);
};

export const hasRequiredFieldValidationErrorByFieldName = (
    object: unknown,
    field: string,
    exactFieldName = true,
): boolean => {
    return hasValidationErrorByCodeAndField(
        object,
        REQUIRED_FIELD,
        field,
        exactFieldName,
    );
};

export const hasInvalidValueValidationErrorByFieldName = (
    object: unknown,
    field: string,
    exactFieldName = true,
): boolean => {
    return hasValidationErrorByCodeAndField(
        object,
        INVALID_VALUE,
        field,
        exactFieldName,
    );
};

export const hasValidationErrorByCodeAndField = (
    object: unknown,
    code: string,
    field: string,
    exactFieldName: boolean,
): boolean =>
    getValidationErrors(object).some(
        (item) =>
            isSameField(exactFieldName, item.field, field) &&
            item.code === code,
    ) ?? false;

const isSameField = (
    exactFieldName: boolean,
    itemField: string,
    field: string,
): boolean => {
    return exactFieldName ? itemField === field : itemField.includes(field);
};

export const isServerValidationErrorCode = (
    code: string,
): code is ServerValidationErrorCode =>
    serverValidationErrorCodes.includes(code as ServerValidationErrorCode);

export const parseServerValidationError = (
    validationError: IValidationError,
): ValidationErrors => {
    const arg = validationError.args?.length > 0 ? validationError.args[0] : '';
    if (isServerValidationErrorCode(validationError.code)) {
        switch (validationError.code) {
            case 'REQUIRED_FIELD':
                return {
                    [ValidationKey.REQUIRED]: true,
                };
            case 'INVALID_VALUE':
                return {
                    [CommonValidationKey.INVALID_VALUE]: true,
                };
            case 'MAX_LENGTH':
                return {
                    [ValidationKey.MAX_LENGTH]: {
                        requiredLength: arg,
                        actualLength: '',
                    },
                };
            case 'MIN_LENGTH':
                return {
                    [ValidationKey.MIN_LENGTH]: { requiredLength: arg },
                };
            default:
                assertUnreachable();
        }
    } else {
        return validationError.defaultMessage
            ? {
                  [ValidationKey.MESSAGE]: validationError.defaultMessage,
              }
            : {
                  [CommonValidationKey.SERVER_GENERIC]: true,
              };
    }
};
