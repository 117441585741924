<button
    *ngIf="{ checked: checked$ | async } as $"
    class="flex grow gap-x-8 text-left"
    type="button"
    [attr.aria-disabled]="disabled"
    [disabled]="disabled"
    [ngClass]="{
        'w-auto': !fullWidth,
        'w-full': fullWidth,
        'ms-auto flex-row-reverse justify-end': labelPosition === 'before',
        'items-center': align === 'center',
        'break-all': wordBreak === 'break-all',
        'break-word': wordBreak === 'break-word'
    }"
    (click)="$.checked !== null && toggle($.checked)"
>
    <interacta-icon
        class="text-text-primary shrink-0 transition-colors"
        [icon]="
            $.checked === 'indeterminate'
                ? 'checkbox-undeterminate'
                : $.checked
                  ? variant === 'square'
                      ? 'checkbox-true'
                      : 'radio-button-true'
                  : variant === 'square'
                    ? 'checkbox-false'
                    : 'radio-button-false'
        "
        [ngClass]="{
            'size-24': size === 'regular',
            'size-20': size === 'small',
            'size-16': size === 'extra-small',
            'text-text-disabled': disabled && !$.checked,
            'text-palette-primary-300': disabled && $.checked,
            'text-text-mid-contrast': !disabled && !$.checked,
            'text-text-primary': !disabled && $.checked
        }"
    />

    <span
        class="grow transition-colors"
        [ngClass]="{
            'opacity-50': disabled
        }"
    >
        <ng-content></ng-content>
    </span>
</button>
