import { Delta } from 'quill/core';

export function deltaPlainTextLength(delta: Delta | null | undefined): number {
    let counter = 0;
    if (delta && !isDeltaEmpty(delta)) {
        const ops = delta.ops;
        ops.forEach((element) => {
            counter +=
                typeof element.insert === 'string' ? element.insert?.length : 0;
        });
    }
    return counter;
}

export function isDeltaEmpty(delta: Delta | null | undefined): boolean {
    return (
        !delta ||
        delta.ops.length === 0 ||
        (delta.ops.length === 1 && delta.ops[0].insert === '\n')
    );
}

export function createDeltaFromString(str: string): Delta {
    return {
        ops: [{ insert: `${str}\n` }],
    } as Delta;
}
