<interacta-input-text-v2
    iconColor="black"
    iconPosition="right"
    [alternativeText]="
        (showPassword
            ? 'BUTTON.ACCESSIBILITY_ALTERNATIVE_LABEL_HIDE_PASSWORD'
            : 'BUTTON.ACCESSIBILITY_ALTERNATIVE_LABEL_SHOW_PASSWORD'
        ) | translate
    "
    [autocomplete]="autocomplete ? 'on' : 'new-password'"
    [control]="control"
    [icon]="showPassword ? 'open-eye' : 'closed-eye'"
    [iconFocusableWithTab]="true"
    [type]="showPassword ? 'text' : 'password'"
    (iconClicked)="showPassword = !showPassword"
></interacta-input-text-v2>
