import {
    animate,
    AnimationTriggerMetadata,
    style,
    transition,
    trigger,
} from '@angular/animations';

export function openHeightAnimation(
    name: string,
    height: number | string,
    msDuration = 200,
    easing: 'ease-out' | 'ease' | 'ease-in' = 'ease-out',
): AnimationTriggerMetadata {
    return trigger(name, [
        transition(':enter', [
            style({
                opacity: 0,
                height: 0,
                paddingTop: 0,
                paddingBottom: 0,
                marginTop: 0,
                marginBottom: 0,
            }),
            animate(
                `${msDuration}ms ${easing}`,
                style({
                    opacity: 1,
                    height: typeof height === 'number' ? `${height}px` : height,
                    paddingTop: '*',
                    paddingBottom: '*',
                    marginTop: '*',
                    marginBottom: '*',
                }),
            ),
        ]),
        transition(':leave', [
            animate(
                `${msDuration}ms ${easing}`,
                style({
                    opacity: 0,
                    height: 0,
                    paddingTop: 0,
                    paddingBottom: 0,
                    marginTop: 0,
                    marginBottom: 0,
                }),
            ),
        ]),
    ]);
}
