<section
    class="bg-surface-100 flex items-center rounded-lg p-8"
    [ngClass]="{
        'gap-x-12': iconSize === 'regular',
        'gap-x-8': iconSize === 'small',
        'text-system-warning': severity === 'warning',
        'text-text-error': severity === 'error'
    }"
>
    @if (showIcon) {
        <interacta-icon
            [icon]="severity === 'info' ? 'info' : 'warning'"
            [ngClass]="{
                'size-24': iconSize === 'regular',
                'size-16': iconSize === 'small'
            }"
        />
    }

    @if (showAsHtml) {
        <p class="typo-xs" [innerHTML]="label"></p>
    } @else {
        <p class="typo-xs">{{ label | translate }}</p>
    }
</section>
