<div *ngIf="control | inputState: isReadonly | async as state" class="relative">
    <input
        #input
        class="h-40 w-full rounded-lg border-2"
        [autocomplete]="autocomplete"
        [formControl]="control"
        [id]="parentIdService?.id ?? internalIdService.id"
        [maxlength]="maxLength ?? null"
        [ngClass]="{
            'pr-8 text-center': alignText === 'center',
            'pr-40': icon && iconPosition === 'right',
            'pl-40': icon && iconPosition === 'left',
            'typo-sm': fontSize === 'small',
            'border-border-error': state.invalid,
            'border-border-300': !state.invalid,
            'focus:border-text': !state.invalid && !state.disabled,
            'bg-surface-100': state.disabled && bgColor === 'default',
            'bg-surface-A text-text': !state.disabled,
            'text-text-disabled pointer-events-none cursor-default':
                state.disabled && !inputClickable,
            'cursor-pointer': state.disabled && inputClickable,
            'text-text-link hover:underline':
                state.disabled &&
                inputClickable &&
                inputClickableTextColor === 'blue',
            'text-text':
                state.disabled &&
                inputClickable &&
                inputClickableTextColor === 'default',
            'pl-36': withMarginLeft,
            'pl-8': !withMarginLeft && iconPosition !== 'left'
        }"
        [placeholder]="label || ''"
        [readonly]="state.disabled"
        [type]="type"
        (blur)="blurEmitter.emit()"
        (focus)="inputFocus.emit($event)"
        (keydown)="keyDownEvent.emit($event)"
        (keyup)="keyUpEvent.emit($event)"
        (paste)="pasteEvent.emit($event)"
    />
    @if (icon && !iconClickable) {
        <interacta-icon
            class="absolute top-4 m-4 size-24"
            [icon]="icon"
            [ngClass]="{
                'text-text': !state.invalid && iconColor === 'black',
                'text-text-mid-contrast':
                    !state.invalid && iconColor === 'gray',
                'text-text-primary': !state.invalid && iconColor === 'primary',
                'text-text-error': state.invalid,
                'left-8': iconPosition === 'left',
                'right-8': iconPosition === 'right'
            }"
        />
    }

    @if (icon && iconClickable) {
        <button
            class="absolute top-4"
            interacta-icon-button
            type="button"
            [attr.aria-hidden]="!alternativeText"
            [attr.aria-label]="alternativeText"
            [icon]="icon"
            [ngClass]="{
                'text-text-mid-contrast':
                    !state.invalid && iconColor === 'gray',
                'text-text-primary': !state.invalid && iconColor === 'primary',
                'text-text-error': state.invalid,
                'left-8': iconPosition === 'left',
                'right-8': iconPosition === 'right'
            }"
            [tabindex]="iconFocusableWithTab ? 0 : -1"
            (click)="iconClicked.emit(); $event.stopPropagation()"
        ></button>
    }
</div>
