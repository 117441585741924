import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BypassSecurityTrustPipe } from '../../pipes';
import { InputIdService } from '../../services';
import { DeltaViewComponent } from '../delta-view/delta-view.component';
import { ShowErrorsComponent } from '../show-errors/show-errors.component';

@Component({
    standalone: true,
    imports: [
        DeltaViewComponent,
        ShowErrorsComponent,
        CommonModule,
        BypassSecurityTrustPipe,
    ],
    selector: 'interacta-form-field-v2',
    template: `
        <div class="flex h-full flex-col justify-between">
            <div class="mb-4 flex flex-col gap-y-4">
                <label class="typo-sm-bold" [for]="inputIdService.id">{{
                    name
                }}</label>
                <interacta-delta-view
                    *ngIf="formattedDescription"
                    class="typo-sm text-text-mid-contrast"
                    [delta]="formattedDescription"
                    [maxLines]="0"
                ></interacta-delta-view>
                <div
                    *ngIf="stringDescription"
                    class="typo-sm text-text-mid-contrast"
                    [innerHTML]="
                        stringDescription | bypassSecurityTrust: 'html'
                    "
                ></div>
            </div>

            <ng-content></ng-content>
            <interacta-show-errors
                *ngIf="showError"
                class="contents"
                [control]="control"
                [inputId]="inputIdService.id"
            ></interacta-show-errors>
        </div>
    `,
    providers: [InputIdService],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormFieldV2Component implements OnChanges {
    @Input({ required: true }) control!: UntypedFormControl;
    @Input({ required: true }) name = '';
    @Input() description: unknown;
    @Input() showError = true;

    formattedDescription: unknown;
    stringDescription: string | null = null;

    constructor(public inputIdService: InputIdService) {}

    ngOnChanges(changes: SimpleChanges): void {
        const newDescription = changes['description']?.currentValue;
        if (
            newDescription &&
            newDescription !== changes['description']?.previousValue
        ) {
            if (typeof newDescription === 'string') {
                this.stringDescription = newDescription;
            } else {
                this.formattedDescription = newDescription;
            }
        }
    }
}
