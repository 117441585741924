/* eslint-disable @angular-eslint/component-selector */
import { NgClass } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    Input,
} from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { IconComponent, InteractaIcon } from '@interacta-shared/ui-icons';
import { Palette, Shape, Size } from '../../model';

@Component({
    selector:
        'button[interacta-icon-button], div[interacta-icon-button], a[interacta-icon-button]',
    templateUrl: './icon-button.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [MatRippleModule, NgClass, IconComponent],
})
export class IconButtonComponent {
    @Input({ required: true }) icon!: InteractaIcon;
    @Input() active = false;
    @Input() bgColor:
        | Extract<
              Palette,
              | 'surface-A'
              | 'surface-B'
              | 'primary'
              | 'system-success'
              | 'system-error'
          >
        | 'transparent'
        | 'reverse-transparent' = 'transparent';
    @Input() shape: Shape = 'round';
    @Input() size: Size = 'regular';
    @Input() border = false;
    @Input() @HostBinding('disabled') disabled?: boolean;
}
