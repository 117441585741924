import { Injectable, inject } from '@angular/core';
import { ErrorDisplayService } from '@interacta-shared/data-access-error';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
    ProTipConfig,
    ProTipPartialData,
    TipAction,
    TipCloseAction,
    TipCloseBehavior,
} from '../models/tip.model';
import * as actions from '../store/tip.actions';

@Injectable({
    providedIn: 'root',
})
export class TipService extends ErrorDisplayService {
    private readonly store = inject(Store);

    success(
        message: unknown,
        translateParams?: Record<string, unknown>,
        closeBehavior?: Extract<TipCloseBehavior, 'duration' | 'manual'>,
    ): void {
        this.store.dispatch(
            actions.success({
                message,
                translateParams,
                closeBehavior,
            }),
        );
    }

    info(
        message: unknown,
        closeBehavior?: TipCloseBehavior,
        translateParams?: Record<string, unknown>,
    ): void {
        this.store.dispatch(
            actions.info({
                message,
                closeBehavior,
                translateParams,
            }),
        );
    }

    warn(
        message: unknown,
        closeBehavior?: TipCloseBehavior,
        translateParams?: Record<string, unknown>,
    ): void {
        this.store.dispatch(
            actions.warn({
                message,
                closeBehavior,
                translateParams,
            }),
        );
    }

    error(
        message: unknown,
        closeBehavior?: TipCloseBehavior,
        translateParams?: Record<string, unknown>,
    ): void {
        this.store.dispatch(
            actions.error({
                message,
                closeBehavior,
                translateParams,
            }),
        );
    }

    /**
     * @param boundary allows to specify the AppRoute(s) where the proTip can be actually opened.
     * If a boundary is provided, the proTip will be closed anyway when navigation action occurs.
     */
    openProTip<A, P>(
        config: ProTipConfig<A, P>,
    ): Observable<TipAction<A> | TipCloseAction> {
        return new Observable((subs) => {
            const factory = () => {
                const callback = (action: TipAction<A>): void => {
                    subs.next(action);
                    subs.complete();
                };
                const actionCallbacks = config.tipActions?.map((action) => ({
                    action,
                    callback,
                }));
                const closeCallback = (): void => {
                    subs.next('close');
                    subs.complete();
                };
                this.store.dispatch(
                    actions.openProTip({
                        ...config,
                        actionCallbacks,
                        closeCallback,
                    } as ProTipPartialData<unknown, unknown>),
                );
            };

            if (config.canOpen == null) {
                factory();
            } else {
                config
                    .canOpen(config.payload)
                    .then((canOpen) => (canOpen ? factory() : subs.complete()));
            }
        });
    }
}
