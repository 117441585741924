import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import {
    ConfigurationService,
    ENVIRONMENT,
} from '@interacta-shared/data-access-configuration';
import { Observable, iif, of } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { ErrorLog } from '../models/error-log.model';
import { CustomError, ErrorType } from '../models/error.model';
import { IValidationError } from '../models/validation-error/validation-error.model';
import { isValidationErrorPayload } from '../models/validation-error/validation-error.util';
import {
    ErrorCloseBehavior,
    ErrorDisplayService,
} from './error-display.abstract-service';

const errorStatusMessages: Record<number, string> = {
    0: 'SHARED.ERROR.HTTP_STATUS.0',
    403: 'SHARED.ERROR.HTTP_STATUS.403',
    409: 'SHARED.ERROR.HTTP_STATUS.409',
    429: 'SHARED.ERROR.HTTP_STATUS.429',
    500: 'SHARED.ERROR.HTTP_STATUS.500',
    504: 'SHARED.ERROR.HTTP_STATUS.504',
};

type ErrorStatusTipBehavior = Record<
    keyof typeof errorStatusMessages,
    ErrorCloseBehavior
>;

const errorStatusTipBehavior: ErrorStatusTipBehavior = {
    0: 'manual',
    403: 'duration',
    409: 'duration',
    429: 'forceReload',
    500: 'duration',
    504: 'duration',
};

@Injectable({ providedIn: 'root' })
export class ErrorService {
    private readonly commonApiBasePath = inject(ENVIRONMENT).apiBasePath.common;

    constructor(
        private http: HttpClient,
        private configurationService: ConfigurationService,
        private errorDisplayService: ErrorDisplayService,
    ) {}

    public log(error: unknown): Observable<ErrorLog> {
        // Send error to server
        const errorToSend = this.addContextInfo(error);
        return this.http
            .post(
                `${this.commonApiBasePath}/core/client-trace/log-error`,
                errorToSend,
            )
            .pipe(map(() => errorToSend));
    }

    public handle(
        responseError: unknown,
        skipConsole?: boolean,
        skipServerLog?: boolean,
    ): void {
        if (!skipConsole) {
            console.error(responseError);
        }

        if (responseError instanceof HttpErrorResponse) {
            return this.responseError(responseError);
        }
        if (responseError instanceof CustomError) {
            return this.customError(responseError);
        }

        iif(
            () => navigator.onLine && !skipServerLog,
            this.log(responseError),
            of(responseError),
        )
            .pipe(
                finalize(() =>
                    this.errorDisplayService.warn(
                        'SHARED.ERROR.UNEXPECTED_ERROR',
                        'forceReload',
                    ),
                ),
            )
            .subscribe({
                error: () =>
                    console.error(
                        'Cannot log error to server side - connection problem.',
                    ),
            });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private addContextInfo(error: any): ErrorLog {
        return {
            frontendUrl: window.location.href,
            message: error.message,
            type: error.type || ErrorType.EXCEPTION,
            stack: typeof error === 'string' ? error : error.stack,
            cause: (error.exception ?? {}).cause,
            clientId: 'Web Portal Frontend',
            clientVersion:
                this.configurationService.getEnvironmentInfo()?.buildInfo
                    .backend?.version ?? '-',
        };
    }

    private responseError(responseError: HttpErrorResponse): void {
        if (isValidationErrorPayload(responseError.error)) {
            const validationErrors: IValidationError[] =
                responseError.error.validationErrors.list ?? [];
            let message = 'SHARED.ERROR.INVALID_FORM';
            let closeBehavior: ErrorCloseBehavior = 'duration';
            if (
                validationErrors.some(
                    (item) => item.code === 'CONCURRENCY_ERROR',
                )
            ) {
                message = 'SHARED.ERROR.CONCURRENCY_ERROR';
                closeBehavior = 'forceReload';
            }
            if (validationErrors.some((item) => item.field === 'pageToken')) {
                message = 'SHARED.ERROR.INVALID_TOKEN';
                closeBehavior = 'forceReload';
            }
            if (
                validationErrors.some((item) => item.code === 'TRANSLATE_ERROR')
            ) {
                message = 'SHARED.ERROR.TRANSLATE_ERROR';
            }
            if (
                validationErrors.some((item) => item.code === 'EXPIRED_SURVEY')
            ) {
                message = 'SHARED.ERROR.EXPIRED_SURVEY';
            }
            if (validationErrors.some((item) => item.code === 'CUSTOM_ERROR')) {
                message =
                    validationErrors.find(
                        (item) => item.code === 'CUSTOM_ERROR',
                    )?.defaultMessage ?? message;
            }
            return this.errorDisplayService.warn(message, closeBehavior);
        }

        const { status, statusText } = responseError;
        if (status === 401 || status === 404 || status === 503) {
            return;
        }

        let message = statusText;
        if (status in errorStatusMessages) {
            // BE error
            //error with status = 0, 403, 409, 429, 500, 504 etc.
            message = errorStatusMessages[status];
            if (!message) {
                message = `${status} - ${statusText}`;
            }
        }

        if (status === 429) {
            this.errorDisplayService.warn(
                message,
                errorStatusTipBehavior[status],
            );
        } else {
            this.errorDisplayService.error(
                message,
                errorStatusTipBehavior[status],
            );
        }
    }

    private customError(error: CustomError): void {
        if (error.warning) {
            this.errorDisplayService.warn(
                error.message,
                error.forceReload ? 'forceReload' : 'manual',
                error.messageParams,
            );
        } else {
            this.errorDisplayService.error(
                error.message,
                error.forceReload ? 'forceReload' : 'manual',
                error.messageParams,
            );
        }
        return;
    }
}
