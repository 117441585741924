import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'interacta-menu-decorator',
    templateUrl: './menu-decorator.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class MenuDecoratorComponent {}
