import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Injectable,
    OnDestroy,
    OnInit,
    Output,
    forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Injectable()
export class CheckboxGroupService<T> {
    selectedValues = new BehaviorSubject<{ values: Set<T>; emit: boolean }>({
        values: new Set(),
        emit: true,
    });

    changeValues(values: T[], emit = true): void {
        this.selectedValues.next({ values: new Set(values), emit });
    }

    nextState(value: T): boolean {
        if (this.selectedValues.value.values.has(value)) {
            this.unselect(value);
            return false;
        } else {
            this.select(value);
            return true;
        }
    }

    private select(value: T): void {
        this.selectedValues.next({
            values: this.selectedValues.value.values.add(value),
            emit: true,
        });
    }

    private unselect(value: T): void {
        this.selectedValues.value.values.delete(value);
        this.selectedValues.next({
            values: this.selectedValues.value.values,
            emit: true,
        });
    }
}

@Component({
    selector: 'interacta-checkbox-group',
    template: `<ng-content></ng-content>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CheckboxGroupComponent),
            multi: true,
        },
        CheckboxGroupService,
    ],
    standalone: true,
})
export class CheckboxGroupComponent<T>
    implements OnInit, OnDestroy, ControlValueAccessor
{
    @Output() changeSelection = new EventEmitter<T[]>();

    private onControlChange?: (value: T[]) => void;
    private onControlTouched?: () => void;
    private destroy$ = new Subject<void>();

    constructor(private checkboxGroupService: CheckboxGroupService<T>) {}

    ngOnInit(): void {
        this.checkboxGroupService.selectedValues
            .pipe(
                takeUntil(this.destroy$),
                filter(({ emit }) => emit),
            )
            .subscribe(({ values }) => {
                this.changeSelection.emit([...values]);

                if (this.onControlChange) {
                    this.onControlChange([...values]);
                }

                if (this.onControlTouched) {
                    this.onControlTouched();
                }
            });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }

    writeValue(values: T[]): void {
        this.checkboxGroupService.changeValues(values, false);
    }

    registerOnChange(fn: (value: T[]) => void): void {
        this.onControlChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onControlTouched = fn;
    }
}
