import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Optional,
    Output,
    Self,
    SimpleChanges,
    SkipSelf,
    ViewChild,
} from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { IconComponent, InteractaIcon } from '@interacta-shared/ui-icons';
import { Size } from '../../model';
import { InputStatePipe } from '../../pipes';
import { InputIdService } from '../../services';
import { IconButtonComponent } from '../icon-button/icon-button.component';

@Component({
    selector: 'interacta-input-text-v2',
    templateUrl: './input-text-V2.component.html',
    providers: [InputIdService],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        ReactiveFormsModule,
        NgClass,
        IconComponent,
        IconButtonComponent,
        AsyncPipe,
        InputStatePipe,
    ],
})
export class InputTextV2Component implements AfterViewInit, OnChanges {
    @Input({ required: true }) control!: UntypedFormControl;
    @Input() icon?: InteractaIcon;
    @Input() iconClickable = true;
    @Input() iconFocusableWithTab = false;
    @Input() iconPosition?: 'right' | 'left';
    @Input() iconColor: 'primary' | 'gray' | 'black' = 'primary';
    @Input() label?: string;
    @Input() isReadonly = false;
    @Input() bgColor: 'default' | 'bg-card' = 'default';
    @Input() type: 'text' | 'email' | 'password' = 'text';
    @Input() fontSize: Extract<Size, 'regular' | 'small'> = 'regular';
    @Input() inputClickable = false;
    @Input() inputClickableTextColor: 'default' | 'blue' = 'default';
    @Input() withMarginLeft = false;
    @Input() focusOnInit = false;
    @Input() maxLength?: number;
    @Input() alignText: 'left' | 'center' | 'right' = 'left';
    @Input() setFocus = false;
    @Input() selectText = false;
    @Input() autocomplete?: string;
    @Input() alternativeText = '';

    @Output() iconClicked = new EventEmitter<void>();
    @Output() blurEmitter = new EventEmitter<void>();
    @Output() inputFocus = new EventEmitter<FocusEvent>();
    @Output() keyUpEvent = new EventEmitter<KeyboardEvent>();
    @Output() keyDownEvent = new EventEmitter<KeyboardEvent>();
    @Output() pasteEvent = new EventEmitter<ClipboardEvent>();

    @ViewChild('input') inputRef?: ElementRef<HTMLInputElement>;

    constructor(
        public elementRef: ElementRef,
        @Self() public internalIdService: InputIdService,
        @SkipSelf() @Optional() public parentIdService?: InputIdService,
    ) {}

    ngAfterViewInit(): void {
        if (this.focusOnInit && !this.isReadonly) {
            this.focus();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['setFocus'] && this.setFocus) {
            this.focus();
            if (this.selectText) {
                this.select();
            }
        }
    }

    focus(preventScroll?: boolean): void {
        this.inputRef?.nativeElement.focus({
            preventScroll,
        });
    }

    select(): void {
        this.inputRef?.nativeElement.select();
    }
}
