<div
    class="flex transition-colors"
    matRipple
    [matRippleDisabled]="disabled ?? false"
    [ngClass]="{
        'rounded-full': shape === 'round',
        'rounded-4': shape === 'rectangular',
        'text-text-primary': active,
        'text-text-disabled': !active && disabled,
        'hover:bg-surface-B':
            !disabled && ['surface-A', 'transparent'].includes(bgColor),
        'hover:bg-surface-B-reverse':
            !disabled && bgColor === 'reverse-transparent',
        'hover:bg-surface-primary-low-contrast':
            !disabled && ['primary', 'surface-B'].includes(bgColor),
        'bg-surface-A': bgColor === 'surface-A',
        'bg-surface-B': bgColor === 'surface-B',
        'bg-surface-primary': bgColor === 'primary',
        'bg-system-success': bgColor === 'system-success',
        'bg-system-error': bgColor === 'system-error',
        'border-border-700 border-2': border,
        'p-6': ['extra-large', 'large'].includes(size),
        'p-4': ['regular', 'small', 'extra-small'].includes(size)
    }"
>
    <interacta-icon
        [icon]="icon"
        [ngClass]="{
            'size-32': size === 'extra-large',
            'size-28': size === 'large',
            'size-24': size === 'regular',
            'size-20': size === 'small',
            'size-16': size === 'extra-small'
        }"
    ></interacta-icon>
</div>
