import { Injectable, inject } from '@angular/core';
import {
    AbstractControl,
    FormGroup,
    UntypedFormGroup,
    ValidatorFn,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { ErrorDisplayService } from './error-display.abstract-service';

interface ValidationError {
    controlName: string;
    errorName: string;
    errorValue: unknown;
}

@Injectable({
    providedIn: 'root',
})
export class FormValidationService {
    private readonly errors = new Subject<ValidationError[]>();
    readonly errors$ = this.errors.asObservable();

    private readonly errorDisplayService = inject(ErrorDisplayService);

    emitErrors(errors: ValidationError[]) {
        this.errors.next(errors);
    }

    isValid(form: FormGroup, noMessage?: boolean): boolean {
        if (!form.valid) {
            const errors = this.getFormValidationErrors(form);

            this.emitErrors(errors);

            const message = errors.every((e) => e.errorName === 'required')
                ? 'SHARED.ERROR.FORM_REQUIRED'
                : 'SHARED.ERROR.INVALID_FORM';

            noMessage || this.errorDisplayService.warn(message);

            return false;
        }

        return true;
    }

    updateValidator(
        prop: AbstractControl,
        validators: ValidatorFn | ValidatorFn[] | null,
    ): void {
        prop.setValidators(validators);
        prop.updateValueAndValidity();
    }

    /**
     * Returns every validation errors of the controls contained in the form,
     * also considering every nested FormGroup inside the controls.
     */
    private getFormValidationErrors(form: FormGroup): ValidationError[] {
        let errors: ValidationError[] = [];
        for (const key of Object.keys(form.controls)) {
            const control = form.controls[key];
            if (control instanceof UntypedFormGroup) {
                const formGroupErrors = this.getFormValidationErrors(
                    control,
                ).map((e) => ({
                    ...e,
                    controlName: `${key} ${e.controlName}`,
                }));
                errors = [...errors, ...formGroupErrors];
            }
            const controlErrors = control.errors;
            if (controlErrors !== null) {
                for (const keyError of Object.keys(controlErrors)) {
                    errors.push({
                        controlName: key,
                        errorName: keyError,
                        errorValue: controlErrors[keyError],
                    });
                }
            }
        }
        return errors;
    }
}
