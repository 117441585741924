export interface IValidationErrorPayload {
    readonly validationErrors: {
        readonly list?: IValidationError[];
    };
}

export interface IValidationError {
    code: string;
    args: string[];
    defaultMessage?: string;
    field: string;
    validationTypeId: number;
    customFieldId: number;
}

// Known server side validation error codes (defined in ValidationExceptionBuilder.java)
export const serverValidationErrorCodes = [
    'REQUIRED_FIELD',
    'INVALID_VALUE',
    'MAX_LENGTH',
    'MIN_LENGTH',
] as const;

export type ServerValidationErrorCode =
    (typeof serverValidationErrorCodes)[number];
