<ng-template
    cdkConnectedOverlay
    cdkConnectedOverlayBackdropClass="cdk-overlay-backdrop"
    [cdkConnectedOverlayGrowAfterOpen]="true"
    [cdkConnectedOverlayHasBackdrop]="true"
    [cdkConnectedOverlayLockPosition]="false"
    [cdkConnectedOverlayOpen]="open"
    [cdkConnectedOverlayOrigin]="origin"
    [cdkConnectedOverlayPositions]="positions"
    [cdkConnectedOverlayPush]="true"
    (backdropClick)="closing.emit()"
    (detach)="closing.emit()"
>
    <div class="dark:shadow-secondary origin-top rounded-2xl" @menu>
        <ng-content></ng-content>
    </div>
</ng-template>
