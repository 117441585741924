import { createAction, props } from '@ngrx/store';
import {
    ProTipPartialData,
    TipCloseBehavior,
    TipData,
} from '../models/tip.model';

export const success = createAction(
    '[Tips] Success',
    props<{
        message: unknown;
        translateParams?: Record<string, unknown>;
        closeBehavior?: Extract<TipCloseBehavior, 'duration' | 'manual'>;
    }>(),
);

export const info = createAction(
    '[Tips] Info',
    props<{
        message: unknown;
        closeBehavior?: TipCloseBehavior;
        translateParams?: Record<string, unknown>;
    }>(),
);

export const warn = createAction(
    '[Tips] Warn',
    props<{
        message: unknown;
        closeBehavior?: TipCloseBehavior;
        translateParams?: Record<string, unknown>;
    }>(),
);

export const error = createAction(
    '[Tips] Error',
    props<{
        message: unknown;
        closeBehavior?: TipCloseBehavior;
        translateParams?: Record<string, unknown>;
    }>(),
);

const openProTipAction = '[Tips] Open Pro Tip';

export const openProTip = createAction(
    openProTipAction,
    props<ProTipPartialData<unknown, unknown>>(),
);

export const setCurrentTip = createAction(
    '[Tip] Set current tip',
    props<{ currentTip: TipData }>(),
);

export const closeCurrentTip = createAction('[Tip] Close current tip');

/**
 * Close courrent tip if it's a ProTip and condition on its payload is matched
 */
export const closeCurrentProTip = createAction(
    '[Tip] Close current pro tip if',
    props<{ condition: (payload: unknown) => Promise<boolean> }>(),
);
