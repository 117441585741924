import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'apply',
    standalone: true,
})
export class ApplyPipe implements PipeTransform {
    transform<T1, T2>(value: T1, fn: (x: T1) => T2): T2 {
        return fn(value);
    }
}
