import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { QuillModule } from 'ngx-quill';
import { Subject } from 'rxjs';
import { ResizeDirective } from '../../directives';
import { Size } from '../../model';

const linesProperty = '--delta-view-line-clamp';
const maxHeight = '--delta-view-max-height';

@Component({
    standalone: true,
    imports: [ResizeDirective, CommonModule, QuillModule],
    selector: 'interacta-delta-view',
    template: `
        <div #container interactaResize (changeSize)="checkShowMore()">
            <quill-view
                #deltaview
                class="delta-view"
                [content]="delta"
                [format]="'object'"
                [ngClass]="{
                    'delta-view--clickable': clickable,
                    'delta-view--text-center': textAlign === 'center',
                    'delta-view--icon-small': iconSize === 'small'
                }"
                [ngStyle]="{ direction: direction === 'rtl' ? 'rtl' : 'ltr' }"
                [style.${linesProperty}]="maxLines"
                [style.${maxHeight}]="maxHeightPx"
                [theme]="'snow'"
            />
        </div>
    `,
    styles: [
        `
            .delta-view .ql-editor {
                overflow: hidden;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: var(${linesProperty});
                max-height: var(${maxHeight});
            }

            .delta-view .ql-editor > p:first-child > br,
            .delta-view .ql-editor > p:last-child > br {
                display: none;
            }

            .delta-view.delta-view--clickable .ql-editor > * {
                cursor: pointer !important;
            }

            /*
             * Hack to make the ul and ol work on Safari when the editor is compact
             * See: https://injenia.atlassian.net/browse/IISP-4534
             */
            .delta-view ul,
            .delta-view ol {
                display: flex;
                flex-direction: column;
            }

            .delta-view .ql-editor {
                text-align: left;
            }

            .delta-view.delta-view--text-center .ql-editor {
                text-align: center;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class DeltaViewComponent implements OnChanges, OnDestroy {
    @Input({ required: true }) delta!: unknown;

    /** maxLines = 0 means it's uncapped */
    @Input() maxLines = 0;
    @Input() clickable = false;
    @Input() direction: 'ltr' | 'rtl' = 'ltr';
    @Input() textAlign: 'left' | 'center' = 'left';

    /**
     * @param iconSize works on table mention icon.
     * The getLineHeight method doesn't take into account  the size
     * (24x24) of the icon, so when the computed line height is less
     * than 20px, the icon is cut off.
     */
    @Input() iconSize: Extract<Size, 'regular' | 'small'> = 'regular';

    @Output() canShowMore = new EventEmitter<boolean>();
    @Output() afterSetMaxHeight = new EventEmitter<void>();

    @ViewChild('container', { static: true })
    container!: ElementRef<HTMLElement>;

    maxHeightPx = '';

    private destroy$ = new Subject<void>();

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['maxLines'] || changes['delta']) {
            this.maxHeightPx = '';
            if (this.maxLines > 0) {
                requestAnimationFrame(() => {
                    this.setMaxHeightPx();
                    this.checkShowMore();
                });
            }
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }

    checkShowMore(): void {
        const qlEditor =
            this.container.nativeElement.querySelector('.ql-editor');

        if (!qlEditor) return;

        const lineHeight = this.getLineHeight();

        this.canShowMore.emit(
            qlEditor.scrollHeight > this.maxLines * lineHeight + lineHeight / 2,
        );
    }

    private getLineHeight(): number {
        const cssLineHeight = getComputedStyle(
            this.container.nativeElement,
        ).lineHeight;
        return parseFloat(cssLineHeight);
    }

    private setMaxHeightPx(): void {
        const lineHeight = this.getLineHeight();
        this.maxHeightPx = `${this.maxLines * lineHeight}px`;
        this.afterSetMaxHeight.emit();
    }
}
