export function isDefined<T>(value: T | null | undefined): value is T {
    return value != null;
}

export function isValidNumber(input: unknown): boolean {
    return isDefined(input) && typeof input === 'number' && !isNaN(input);
}

export function isValidString(input: unknown): boolean {
    return isDefined(input) && typeof input === 'string' && input.trim() !== '';
}
