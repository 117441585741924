import {
    AbstractControl,
    FormControl,
    ValidationErrors,
    ValidatorFn,
} from '@angular/forms';
import { isDefined, ValidationKey } from '@interacta-shared/util';
import { utcToZonedTime } from 'date-fns-tz';
import { Delta } from 'quill/core';
import { deltaPlainTextLength } from '../utils/delta.util';

export enum CommonValidationKey {
    CHECK_PASSWORD_EQUALS = 'checkPasswordEquals',
    MAX_LENGTH_LIST = 'maxlengthMultiSelect',
    MIN_LENGTH_LIST = 'minlengthMultiSelect',
    REQUIRED_FIELD = 'requiredField',
    DATE_GREATER_THAN = 'dateGreaterThan',
    DATE_LESS_THAN = 'dateLessThan',
    INVALID_OTP_CODE = 'invalidOtpCode',

    // Utility keys, not related to specific validators

    /**
     * A generic code used to report a failed server side validation
     */
    INVALID_VALUE = 'invalid',

    /**
     * Used as fallback for handle failed server side validation when no error code is returned
     */
    SERVER_GENERIC = 'serverGeneric',
}

export const requiredField = (requiredField: string): ValidatorFn => {
    return (c: AbstractControl): ValidationErrors | null => {
        const result: ValidationErrors = {
            [CommonValidationKey.REQUIRED_FIELD]: {
                requiredField,
            },
        };

        return !c.value ? result : null;
    };
};

export const requiredList = (c: AbstractControl): ValidationErrors | null => {
    const result: ValidationErrors = {
        [ValidationKey.REQUIRED]: {},
    };

    return !c.value || (Array.isArray(c.value) && c.value.length === 0)
        ? result
        : null;
};

export const checkPasswordEquals = (pwd1: FormControl<string>): ValidatorFn => {
    return (pwd2: AbstractControl): ValidationErrors | null => {
        const result: ValidationErrors = {
            [CommonValidationKey.CHECK_PASSWORD_EQUALS]: {},
        };

        return pwd1.value !== pwd2.value ? result : null;
    };
};

export const maxLengthList = (param: number): ValidatorFn => {
    return (c: AbstractControl): ValidationErrors | null => {
        const actualLength =
            c.value && c.value.length > 0 ? c.value.length : -1;
        const result: ValidationErrors = {
            [CommonValidationKey.MAX_LENGTH_LIST]: {
                requiredLength: param,
                actualLength,
            },
        };

        return actualLength != -1 && actualLength > param ? result : null;
    };
};

export const minLengthList = (param: number): ValidatorFn => {
    return (c: AbstractControl): ValidationErrors | null => {
        const result = {
            [CommonValidationKey.MIN_LENGTH_LIST]: {
                requiredLength: param,
            },
        };

        const num = c.value && c.value.length > 0 ? c.value.length : -1;
        return num != -1 && num < param ? result : null;
    };
};

export const requiredDelta = (
    c: AbstractControl<Delta | null | undefined>,
): ValidationErrors | null => {
    const result = {
        [ValidationKey.REQUIRED]: true,
    };
    return deltaPlainTextLength(c.value) ? null : result;
};

export const maxLengthDelta = (param: number): ValidatorFn => {
    return (
        c: AbstractControl<Delta | null | undefined>,
    ): ValidationErrors | null => {
        const actualLength = deltaPlainTextLength(c.value);
        const result: ValidationErrors = {
            [ValidationKey.MAX_LENGTH]: {
                requiredLength: param,
                actualLength,
            },
        };

        return actualLength > param ? result : null;
    };
};

export const minLengthDelta = (param: number): ValidatorFn => {
    return (c: AbstractControl): ValidationErrors | null => {
        const result: ValidationErrors = {
            [ValidationKey.MIN_LENGTH]: {
                requiredLength: param,
            },
        };

        return deltaPlainTextLength(c.value) < param ? result : null;
    };
};

export const dateMustBeLessThanOrEqual = (
    date: Date,
    isDateTime = false,
): ValidatorFn => {
    return (c: AbstractControl): ValidationErrors | null => {
        if (
            !c.value ||
            !isDefined(date) ||
            date.getTime() >= c.value.getTime()
        ) {
            return null;
        }
        let dateString = [
            pad(date.getDate()),
            pad(date.getMonth() + 1),
            date.getFullYear(),
        ].join('/');
        if (isDateTime) {
            const timeString = [
                pad(date.getHours()),
                pad(date.getMinutes()),
            ].join(':');
            dateString = `${dateString} ${timeString}`;
        }
        const result: ValidationErrors = {
            [CommonValidationKey.DATE_LESS_THAN]: {
                date: dateString,
            },
        };
        return result;
    };
};

export const dateMustBeGreaterThanOrEqual = (
    date: Date,
    isDateTime = false,
    timezone?: string,
): ValidatorFn => {
    return (c: AbstractControl): ValidationErrors | null => {
        const minDate = timezone ? utcToZonedTime(date, timezone) : date;
        const controlDate = c.value;

        if (
            !controlDate ||
            !isDefined(minDate) ||
            minDate.getTime() <= controlDate.getTime()
        ) {
            return null;
        }

        let dateString = [
            pad(date.getDate()),
            pad(date.getMonth() + 1),
            date.getFullYear(),
        ].join('/');
        if (isDateTime) {
            const timeString = [
                pad(date.getHours()),
                pad(date.getMinutes()),
            ].join(':');
            dateString = `${dateString} ${timeString}`;
        }
        const result = {
            [CommonValidationKey.DATE_GREATER_THAN]: {
                date: dateString,
            },
        };
        return result;
    };
};

const pad = (s: number): string => {
    return s < 10 ? `0${s}` : `${s}`;
};

export const invalidOtpCode = (): ValidatorFn => {
    return (): ValidationErrors | null => {
        return {
            [CommonValidationKey.INVALID_OTP_CODE]: {},
        };
    };
};
