import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({
    name: 'bypassSecurityTrust',
    standalone: true,
})
export class BypassSecurityTrustPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {}

    transform(
        value: string,
        type: 'resource-url' | 'html' = 'resource-url',
    ): SafeResourceUrl {
        switch (type) {
            case 'resource-url':
                return this.sanitizer.bypassSecurityTrustResourceUrl(value);
            case 'html':
                return this.sanitizer.bypassSecurityTrustHtml(value);
        }
    }
}
